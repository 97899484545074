
import React from 'react'
import { Template } from '../components/template';
const config = {
    "name": "为所欲为",
    "url": "/weisuoyuwei/",
    "gif": "/template/weisuoyuwei.gif",
    "config": [
        {
            "default": "好啊",
            "startTime": 1.28,
            "endTime": 1.66
        },
        {
            "default": "我是一等良民就不说",
            "startTime": 3.38,
            "endTime": 4.63
        },
        {
            "default": "即使一定要冤枉我",
            "startTime": 5.16,
            "endTime": 7.53
        },
        {
            "default": "我也有钱聘请大律师帮我",
            "startTime": 7.76,
            "endTime": 10.09
        },
        {
            "default": "我想我别指望坐牢了",
            "startTime": 10.22,
            "endTime": 11.76
        },
        {
            "default": "你别以为有钱就能为所欲为",
            "startTime": 11.93,
            "endTime": 13.06
        },
        {
            "default": "抱歉，有钱是真的能为所欲为的",
            "startTime": 13.81,
            "endTime": 16.31
        },
        {
            "default": "但我看他领会不到这种意境",
            "startTime": 18.06,
            "endTime": 19.56
        },
        {
            "default": "领会不到",
            "startTime": 19.6,
            "endTime": 21.6
        }
    ]
}

export { config };
export default () => (
    <Template element={config} />
)